import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CHECKOUT_OUR_RECENT_WORK_DATA, LINK_OF_ALL_PAGES } from "const/common";
import Image from "next/image";
import PrimaryButton from "./PrimaryButton";

const PortfolioOverview = () => (
  <div className="px-4 md:py-16 md:px-10">
    <div className="flex justify-between items-center pt-16 pb-12 md:py-16">
      <h2 className="lg:text-6xl text-black font-medium text-3xl md:text-4xl">
        <span>Check out our recent work</span>
      </h2>
      <div className="hidden md:block">
        <PrimaryButton
          arrow={false}
          link={LINK_OF_ALL_PAGES.mainPages.portfolio}
          val="Check our portfolio"
        />
      </div>
    </div>
    {CHECKOUT_OUR_RECENT_WORK_DATA.map((value, index) => (
      <div
        key={value.title}
        className={`flex flex-col pb-16 md:py-16 ${
          index % 2 === 0 ? "xl:flex-row" : "xl:flex-row-reverse"
        }`}
      >
        <div className="w-full xl:w-1/2">
          <div className="md:px-0 lg:pr-3 overflow-hidden">
            <div className="overflow-hidden rounded-[5%] flex">
              <Image
                alt={value.alt}
                className="w-full h-fit transition-all duration-1000 ease-out hover:ease-in hover:duration-700 hover:rounded-7.5 hover:scale-110"
                height={1000}
                src={value.src}
                title={value.imagTitle}
                width={1000}
              />
            </div>
          </div>
        </div>
        <div className="w-full xl:w-1/2 lg:pr-3">
          <div className="md:p-8 xl:py-0">
            <h3 className="text-xl mt-12 mb-4 md:text-2xl lg:text-3xl xl:text-4xl font-medium md:mb-8 md:mt-0">
              <span dangerouslySetInnerHTML={{ __html: value.title }}></span>
            </h3>
            <p className="text-lg font-normal text-black lg:text-base xl:text-lg mb-4">
              <span>{value.about}</span>
            </p>
            <div className="mb-8 md:mb-4 lg:mb-8">
              <a
                className="text-base md:text-lg xl:text-xl font-medium text-primary-yellow no-underline"
                href={value.link}
              >
                <span className="flex items-center">
                  View More
                  <span className="ml-2">
                    <FontAwesomeIcon
                      height={20}
                      icon={faArrowRightLong}
                      width={40}
                    />
                  </span>
                </span>
              </a>
            </div>
            <span className="text-4xl md:text-5xl lg:text-7xl font-medium text-black">
              “
            </span>
            <p className="text-base md:text-lg font-normal text-black -mt-6 pt-4 pr-8 pb-4">
              <span
                dangerouslySetInnerHTML={{ __html: value.description }}
                className="[&>a]:text-blue-700"
              ></span>
            </p>
            <p className="text-sm md:text-lg lg:text-xl font-bold text-black">
              <span>{value.company}</span>
            </p>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default PortfolioOverview;
