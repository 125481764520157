import Image from "next/image";
import { LINK_OF_ALL_PAGES } from "./common";
const SHOPIFY_DEVELOPMENT = "Shopify Development";

export const WE_EXCEL_DATA = [
  {
    title: "Front End",
    techsname: [
      {
        name: "React",
        link: LINK_OF_ALL_PAGES.services.hireReactJSDeveloper,
        image: (
          <Image
            key={1}
            alt="Hire React js Developer"
            height={60}
            objectFit="contain"
            src="/assets/common/react-white.webp"
            title="Hire React js Developer"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={1}
            alt="Hire React js Developer"
            height={60}
            objectFit="contain"
            src="/assets/common/react.webp"
            title="Hire React js Developer"
            width={70}
          />
        ),
      },
      {
        name: "Angular",
        link: LINK_OF_ALL_PAGES.services.hireAngularDeveloper,
        image: (
          <Image
            key={2}
            alt="Angular Development"
            height={60}
            objectFit="contain"
            src="/assets/home/angular-white.webp"
            title="Angular Development"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={2}
            alt="Angular Development"
            height={60}
            objectFit="contain"
            src="/assets/common/angular.webp"
            title="Angular Development"
            width={70}
          />
        ),
      },
      {
        name: "Vue",
        link: LINK_OF_ALL_PAGES.services.hireVueJSDeveloper,
        image: (
          <Image
            key={3}
            alt="VueJS Development"
            height={60}
            objectFit="contain"
            src="/assets/home/vue-white.webp"
            title="VueJS Development"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={3}
            alt="VueJS Development"
            height={60}
            objectFit="contain"
            src="/assets/home/vue.webp"
            title="VueJS Development"
            width={70}
          />
        ),
      },
      {
        name: "HTML5",
        image: (
          <Image
            key={4}
            alt="HTML 5"
            height={60}
            objectFit="contain"
            src="/assets/home/html-white.webp"
            title="HTML 5"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={4}
            alt="HTML 5"
            height={60}
            objectFit="contain"
            src="/assets/home/html.webp"
            title="HTML 5"
            width={70}
          />
        ),
      },
      {
        name: "Next",
        link: LINK_OF_ALL_PAGES.services.hireNextJSDeveloper,
        image: (
          <Image
            key={5}
            alt="Hire Next.js Developers"
            height={60}
            objectFit="contain"
            src="/assets/common/next-js-white.webp"
            title="Hire Next.js Developers"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={5}
            alt="Hire Next.js Developers"
            height={60}
            objectFit="contain"
            src="/assets/home/next-js.webp"
            title="Hire Next.js Developers"
            width={70}
          />
        ),
      },
      {
        name: "Gatsby",
        image: (
          <Image
            key={6}
            alt="Gatsby"
            height={60}
            objectFit="contain"
            src="/assets/common/gatsby-white.webp"
            title="Gatsby"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={6}
            alt="Gatsby"
            height={60}
            objectFit="contain"
            src="/assets/home/gatsby.webp"
            title="Gatsby"
            width={70}
          />
        ),
      },
      {
        name: "Nuxtjs",
        image: (
          <Image
            key={7}
            alt="Nuxt js Development"
            height={60}
            objectFit="contain"
            src="/assets/common/nuxt-js-white.webp"
            title="Nuxt js Development"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={7}
            alt="Nuxt js Development"
            height={60}
            objectFit="contain"
            src="/assets/home/nuxt-js.webp"
            title="Nuxt js Development"
            width={70}
          />
        ),
      },
    ],
  },
  {
    title: "Back End",
    techsname: [
      {
        name: "Node",
        link: LINK_OF_ALL_PAGES.services.hireNodeJSDeveloper,
        image: (
          <Image
            key={1}
            alt="Hire Node js Developer"
            height={60}
            objectFit="contain"
            src="/assets/common/node-js-white.webp"
            title="Hire Node js Developer"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={1}
            alt="Hire Node js Developer"
            height={60}
            objectFit="contain"
            src="/assets/home/node-js.webp"
            title="Hire Node js Developer"
            width={70}
          />
        ),
      },
      {
        name: "Laravel",
        link: LINK_OF_ALL_PAGES.services.hireLaravelDeveloper,
        image: (
          <Image
            key={2}
            alt="Laravel Development"
            height={60}
            objectFit="contain"
            src="/assets/common/laravel-white.webp"
            title="Laravel Development"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={2}
            alt="Laravel Development"
            height={60}
            objectFit="contain"
            src="/assets/home/laravel.webp"
            title="Laravel Development"
            width={70}
          />
        ),
      },
      {
        name: "Python",
        link: LINK_OF_ALL_PAGES.services.hirePythonDeveloper,
        image: (
          <Image
            key={3}
            alt="Phython  Development "
            height={60}
            objectFit="contain"
            src="/assets/common/python-white.webp"
            title="Phython  Development"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={3}
            alt="Phython  Development "
            height={60}
            objectFit="contain"
            src="/assets/home/python.webp"
            title="Phython Development"
            width={70}
          />
        ),
      },
      {
        name: "Golang",
        link: LINK_OF_ALL_PAGES.services.hireGolangDeveloper,
        image: (
          <Image
            key={4}
            alt="Golang Development"
            height={60}
            objectFit="contain"
            src="/assets/common/golang-white.webp"
            title="Golang Development"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={4}
            alt="Golang Development"
            height={60}
            objectFit="contain"
            src="/assets/home/golang.webp"
            title="Golang Development"
            width={70}
          />
        ),
      },
      {
        name: "ROR",
        link: LINK_OF_ALL_PAGES.services.hireRoRDeveloper,
        image: (
          <Image
            key={5}
            alt="ROR Development"
            height={60}
            objectFit="contain"
            src="/assets/common/ror-white.webp"
            title="ROR Development"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={5}
            alt="ROR Development"
            height={60}
            objectFit="contain"
            src="/assets/home/ror.webp"
            title="ROR Development"
            width={70}
          />
        ),
      },
    ],
  },
  {
    title: "Mobile App",
    techsname: [
      {
        name: "ios",
        link: LINK_OF_ALL_PAGES.services.hireiOSDeveloper,
        image: (
          <Image
            key={1}
            alt="Hire ios  Developer"
            height={60}
            objectFit="contain"
            src="/assets/common/ios-white.webp"
            title="Hire ios  Developer"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={1}
            alt="Hire ios  Developer"
            height={60}
            objectFit="contain"
            src="/assets/home/ios.webp"
            title="Hire ios  Developer"
            width={70}
          />
        ),
      },
      {
        name: "Kotlin",
        link: LINK_OF_ALL_PAGES.services.hireKotlinDeveloper,
        image: (
          <Image
            key={2}
            alt="Hire Kotlin Developer"
            height={60}
            objectFit="contain"
            src="/assets/common/kotlin-white.webp"
            title="Hire Kotlin Developer"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={2}
            alt="Hire Kotlin Developer"
            height={60}
            objectFit="contain"
            src="/assets/home/kotlin.webp"
            title="Hire Kotlin Developer"
            width={70}
          />
        ),
      },
      {
        name: "Swift",
        link: LINK_OF_ALL_PAGES.services.hireSwiftDeveloper,
        image: (
          <Image
            key={3}
            alt="Swift Development"
            height={60}
            objectFit="contain"
            src="/assets/common/swift-white.webp"
            title="Swift Development"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={3}
            alt="Swift Development"
            height={60}
            objectFit="contain"
            src="/assets/home/swift.webp"
            title="Swift Development"
            width={70}
          />
        ),
      },
      {
        name: "Android",
        link: LINK_OF_ALL_PAGES.services.hireAndroidDeveloper,
        image: (
          <Image
            key={4}
            alt=" Android Development"
            height={60}
            objectFit="contain"
            src="/assets/common/android-white.webp"
            title="Android Development"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={4}
            alt=" Android Development"
            height={60}
            objectFit="contain"
            src="/assets/home/android.webp"
            title="Android Development"
            width={70}
          />
        ),
      },
      {
        name: "Flutter",
        link: LINK_OF_ALL_PAGES.services.hireFlutterDeveloper,
        image: (
          <Image
            key={5}
            alt="Hire Flutter App Developer"
            height={60}
            objectFit="contain"
            src="/assets/common/flutter-white.webp"
            title="Hire Flutter App Developer"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={5}
            alt="Hire Flutter App Developer"
            height={60}
            objectFit="contain"
            src="/assets/home/flutter.webp"
            title="Hire Flutter App Developer"
            width={70}
          />
        ),
      },
      {
        name: "React Native",
        link: LINK_OF_ALL_PAGES.services.hireReactNativeDeveloper,
        image: (
          <Image
            key={6}
            alt="React Native Development"
            height={60}
            objectFit="contain"
            src="/assets/common/react-white.webp"
            title="React Native Development"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={6}
            alt="React Native Development"
            height={60}
            objectFit="contain"
            src="/assets/common/react.webp"
            title="React Native Development"
            width={70}
          />
        ),
      },
      {
        name: "Java",
        image: (
          <Image
            key={6}
            alt=" Java  Development"
            height={60}
            objectFit="contain"
            src="/assets/common/java-white.webp"
            title="Java  Development"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={7}
            alt=" Java  Development"
            height={60}
            objectFit="contain"
            src="/assets/home/java.webp"
            title="Java  Development"
            width={70}
          />
        ),
      },
    ],
  },
  {
    title: "Open Source",
    techsname: [
      {
        name: "Shopify",
        link: LINK_OF_ALL_PAGES.services.hireShopifyDeveloper,
        image: (
          <Image
            key={3}
            alt={SHOPIFY_DEVELOPMENT}
            height={60}
            objectFit="contain"
            src="/assets/common/shopify-white.webp"
            title={SHOPIFY_DEVELOPMENT}
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={3}
            alt={SHOPIFY_DEVELOPMENT}
            height={60}
            objectFit="contain"
            src="/assets/home/shopify.webp"
            title={SHOPIFY_DEVELOPMENT}
            width={70}
          />
        ),
      },
    ],
  },
  {
    title: "Cloud & Devops",
    techsname: [
      {
        name: "AWS",
        image: (
          <Image
            key={1}
            alt="AWS"
            height={60}
            objectFit="contain"
            src="/assets/common/aws-white.webp"
            title="AWS"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={1}
            alt="AWS"
            height={60}
            objectFit="contain"
            src="/assets/home/aws.webp"
            title="AWS"
            width={70}
          />
        ),
      },
      {
        name: "Azure",
        image: (
          <Image
            key={2}
            alt="Azure"
            height={60}
            objectFit="contain"
            src="/assets/common/azure-white.webp"
            title="Azure"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={2}
            alt="Azure"
            height={60}
            objectFit="contain"
            src="/assets/home/azure.webp"
            title="Azure"
            width={70}
          />
        ),
      },
      {
        name: "Google Cloud",
        image: (
          <Image
            key={3}
            alt="Google Cloud"
            height={60}
            objectFit="contain"
            src="/assets/common/google-cloud-white.webp"
            title="Google Cloud"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={3}
            alt="Google Cloud"
            height={60}
            objectFit="contain"
            src="/assets/home/google-cloud.webp"
            title="Google Cloud"
            width={70}
          />
        ),
      },
      {
        name: "Docker",
        image: (
          <Image
            key={4}
            alt="Docker"
            height={60}
            objectFit="contain"
            src="/assets/common/docker-white.webp"
            title="Docker"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={4}
            alt="Docker"
            height={60}
            objectFit="contain"
            src="/assets/home/docker.webp"
            title="Docker"
            width={70}
          />
        ),
      },
      {
        name: "Kubernetes",
        image: (
          <Image
            key={5}
            alt="Kubernetes"
            height={60}
            objectFit="contain"
            src="/assets/common/kubernetes-white.webp"
            title="Kubernetes"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={5}
            alt="Kubernetes"
            height={60}
            objectFit="contain"
            src="/assets/home/kubernetes.webp"
            title="Kubernetes"
            width={70}
          />
        ),
      },
      {
        name: "Jenkins",

        image: (
          <Image
            key={6}
            alt="Jenkins"
            height={60}
            objectFit="contain"
            src="/assets/common/jenkins-white.webp"
            title="Jenkins"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={6}
            alt="Jenkins"
            height={60}
            objectFit="contain"
            src="/assets/home/jenkins.webp"
            title="Jenkins"
            width={70}
          />
        ),
      },
      {
        name: "CI/ CDS",
        image: (
          <Image
            key={7}
            alt="CI/CDS"
            height={60}
            objectFit="contain"
            src="/assets/common/ci-cds-white.webp"
            title="CI/CDS"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={7}
            alt="CI/CDS"
            height={60}
            objectFit="contain"
            src="/assets/home/ci-cds.webp"
            title="CI/CDS"
            width={70}
          />
        ),
      },
    ],
  },
  {
    title: "Database",
    techsname: [
      {
        name: "Mongodb",

        image: (
          <Image
            key={1}
            alt="MongoDB"
            height={60}
            objectFit="contain"
            src="/assets/common/mongodb-white.webp"
            title="MongoDB"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={1}
            alt="MongoDB"
            height={60}
            objectFit="contain"
            src="/assets/home/mongodb.webp"
            title="MongoDB"
            width={70}
          />
        ),
      },
      {
        name: "PostgreSQL",
        image: (
          <Image
            key={2}
            alt="Postgre SQL"
            height={60}
            objectFit="contain"
            src="/assets/common/postgresql-white.webp"
            title="Postgre SQL"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={2}
            alt="Postgre SQL"
            height={60}
            objectFit="contain"
            src="/assets/home/postgresql.webp"
            title="Postgre SQL"
            width={70}
          />
        ),
      },
      {
        name: "DynamoDB",
        image: (
          <Image
            key={3}
            alt="Dynamo DB"
            height={60}
            objectFit="contain"
            src="/assets/common/dynamodb-white.webp"
            title="DynamoDB"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={3}
            alt="Dynamo DB"
            height={60}
            objectFit="contain"
            src="/assets/home/dynamodb.webp"
            title="DynamoDB"
            width={70}
          />
        ),
      },
      {
        name: "MySQL",
        image: (
          <Image
            key={4}
            alt="MySQL"
            height={60}
            objectFit="contain"
            src="/assets/common/mysql-white.webp"
            title="MySQL"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={4}
            alt="MySQL"
            height={60}
            objectFit="contain"
            src="/assets/home/mysql.webp"
            title="MySQL"
            width={70}
          />
        ),
      },
      {
        name: "MariaDB",
        image: (
          <Image
            key={5}
            alt="MariaDB"
            height={60}
            objectFit="contain"
            src="/assets/common/mariadb-white.webp"
            title="MariaDB"
            width={70}
          />
        ),
        hoverImage: (
          <Image
            key={5}
            alt="MariaDB"
            height={60}
            objectFit="contain"
            src="/assets/home/mariadb.webp"
            title="MariaDB"
            width={70}
          />
        ),
      },
    ],
  },
];
export const SERVICES_LIST_DATA = [
  {
    id: 0,
    link: LINK_OF_ALL_PAGES.services.customSoftwareDevelopment,
    heading: "Custom Software Development",
    list: [
      "Custom Web Development",
      "Custom Mobile App Development",
      " UI/UX Designing",
      " Cloud and Devops",
      "Enterprise Software Solutions",
    ],
  },
  {
    id: 1,
    link: LINK_OF_ALL_PAGES.services.staffAugmentation,
    heading: "Staff Augmentation Services",
    list: [
      "On-demand Product Teams",
      "Agile Development",
      "Hire Dedicated Resources",
      "Team Augmentation",
      "Free Trial Options",
    ],
  },
  {
    id: 2,
    link: LINK_OF_ALL_PAGES.services.digitalMarketing,
    heading: "Digital Marketing Services",
    list: [
      "Social Media Marketing",
      " Search Engine Optimization",
      "Paid Facebook Marketing",
      " Google Pay Per Click Ads",
      "Social Media Designing",
    ],
  },
  {
    id: 3,
    link: LINK_OF_ALL_PAGES.services.itConsultationAssistance,
    heading: "IT Consultation & Assistance",
    list: [
      "Product Consultation",
      "Scope & Interactive Prototype",
      " Digital Transformation Consultation",
      " Architecture and Code Assessment",
      "Product Maintenance & Support",
    ],
  },
];
