import { COUNTER_DATA, LINK_OF_ALL_PAGES } from "const/common";
import CounterComponent from "../common/CounterComponent";
import PrimaryButton from "../common/PrimaryButton";

const AboutUs = () => (
  <div className="bg-primary-black py-12 px-4 md:py-16 md:px-10">
    <div className="flex flex-col xl:flex-row items-center">
      <div className="w-full xl:w-1/2">
        <div className="pb-12 md:pb-0 mb-4 md:mb-0">
          <p className="font-medium text-white pb-4 md:pr-8 md:pb-0 xl:mr-6 xl:pr-2 text-3xl md:text-4xl xl:text-6xl mb-7 md:mb-10 xl:mb-14 leading-snug md:leading-relaxed xl:leading-tight">
            <span>
              We are your Technology partner with proven expertise and
              experience to create robust solutions.
            </span>
          </p>
          <PrimaryButton
            arrow={false}
            link={LINK_OF_ALL_PAGES.mainPages.aboutUltroneous}
            val="About us"
          />
        </div>
      </div>
      <div className="w-full xl:w-1/2">
        <CounterComponent
          data={COUNTER_DATA}
          mainContainerClass="md:py-16 xl:py-16 xl:pl-28 2xl:pl-40"
          secondMainContainerClass="md:flex md:flex-col md:items-center xl:block"
        />
      </div>
    </div>
  </div>
);

export default AboutUs;
