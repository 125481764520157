import
  {
    faArrowLeftLong,
    faArrowRightLong,
  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import
  {
    button_value,
    INDUSTRIES_SERVE_DATA,
    INDUSTRIES_SLIDER_DATA,
    LINK_OF_ALL_PAGES,
  } from "const/common";
import { memo, useRef, useState } from "react";
import Slider from "react-slick";
import Container from "./Container";
import PrimaryButton from "./PrimaryButton";

const IndustryServeSlider = ({ className, customStyle }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
  };
  const sliderReference = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const jumpToSlide = (slide) => {
    setActiveSlide(slide);
    sliderReference.current.slickGoTo(slide);
  };

  return (
    <Container large className={`bg-primary-black ${className}`}>
      <div className="py-8 px-3 md:pb-16 md:px-0">
        <h2
          className={`text-3xl md:text-6xl md:leading-tight font-medium text-center text-white pt-4 md:pt-16 ${customStyle}`}
        >
          <span>
            Serving Clients <br /> across Every Industry
          </span>
        </h2>
        <ul className="flex justify-start md:justify-center items-center w-full overflow-auto pt-8 pb-16 md:pt-24 md:px-10 hide-scrollbar">
          {INDUSTRIES_SERVE_DATA.map((itm, index) => (
            <li
              key={itm.title}
              onClick={() => {
                jumpToSlide(index);
              }}
              className={`list-none cursor-pointer py-3 mx-5 whitespace-nowrap text-xl first:ml-0 border-2 border-solid border-t-0 border-x-0 ${
                activeSlide === index ? "border-white" : "border-transparent"
              } ${
                activeSlide === index
                  ? "text-white"
                  : "text-opacity-40 text-white"
              }`}
            >
              <span>{itm.title}</span>
            </li>
          ))}
        </ul>
        <div className="flex justify-center flex-wrap flex-col lg:flex-row bg-primary-black items-center industryServeSlider">
          <div className="w-full xl:w-1/12">
            <div
              className={`hidden text-3xl text-white border border-solid border-white rounded-full xl:flex justify-center items-center w-12 h-12 mx-auto cursor-pointer 
               ${Number(activeSlide) === 0 ? "opacity-50" : ""}`}
              role="presentation"
              onClick={() => {
                Number(activeSlide) === 0
                  ? undefined
                  : jumpToSlide(activeSlide === 0 ? 4 : activeSlide - 1);
              }}
            >
              <FontAwesomeIcon
                height={"30px"}
                icon={faArrowLeftLong}
                width={"25px"}
              />
            </div>
          </div>
          <div className="w-full xl:w-10/12">
            <Slider {...settings} ref={sliderReference}>
              {INDUSTRIES_SLIDER_DATA.map((_value) => (
                <div key={_value.image}>
                  <div
                    className="rounded-2xl overflow-hidden mx-2 md:mx-4 h-fit"
                    style={{ background: `${_value.color}` }}
                  >
                    <div className="flex flex-col xl:flex-row">
                      <div className="w-full xl:w-5/12">
                        <h3 className="text-xl font-medium text-black py-8 px-4 md:pt-12 md:pb-8 md:pl-8 xl:pl-16 xl:pt-16 xl:pr-0">
                          <span>{_value.heading}</span>
                        </h3>
                        <p className="text-base font-medium pr-4 pb-8 pl-4 md:pt-12 md:pb-8 xl:pb-16 xl:pl-16 xl:pt-0 text-gray-600 text-opacity-80">
                          <span>{_value.details}</span>
                        </p>
                        <div className="pb-8 pl-4 md:pb-16 md:pl-8 xl:pb-32 xl:pl-16">
                          <PrimaryButton
                            arrow={false}
                            link={LINK_OF_ALL_PAGES.mainPages.contactUs}
                            val={button_value}
                          />
                        </div>
                      </div>
                      <div
                        className="bg-cover bg-no-repeat pt-80 md:pt-96 md:pb-60 xl:py-0 w-full xl:w-7/12"
                        style={{
                          backgroundImage: `url(${_value.image})`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="w-full lg:w-1/12">
            <div
              className={`hidden text-3xl text-white border border-solid border-white rounded-full xl:flex justify-center items-center w-12 h-12 mx-auto cursor-pointer ${
                INDUSTRIES_SLIDER_DATA.length - 1 === activeSlide * 1
                  ? "opacity-50"
                  : ""
              }`}
              id="arrow"
              role="presentation"
              onClick={() => {
                INDUSTRIES_SLIDER_DATA.length - 1 === activeSlide * 1
                  ? undefined
                  : jumpToSlide(activeSlide === 4 ? 0 : +activeSlide + 1);
              }}
            >
              <FontAwesomeIcon
                height="30px"
                icon={faArrowRightLong}
                width="25px"
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default memo(IndustryServeSlider);
