import Head from 'next/head';
import { Fragment } from 'react';
import Cookie from '../common/Cookie';
import Footer from './Footer';
import MegaMenu from './MegaMenu';


export default function Layout({
  title,
  keywords,
  description,
  children,
  uniqueMeta,
  ogTitle,
  ogType,
  ogUrl,
  ogDescription,
  ogImage,
  twitterTitle,
  twitterDescription,
  twitterImage,
  canonicalLink
}) {
  return (
    <Fragment>
      <Head>
        <title>{title}</title>
        <meta
          key="ultroneous-key-description"
          content={description}
          name="description"
        />
        <meta
          key="ultroneous-key-keywords"
          content={keywords}
          name="keywords"
        />

        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="#E49B00" name="theme-color" />

        <meta content={ogTitle} property="og:title" />
        <meta content={ogType} property="og:type" />
        <meta content="ultroNeous Technologies" property="og:site_name" />
        <meta content={ogUrl} property="og:url" />
        <meta content={ogDescription} property="og:description" />
        <meta content={ogImage} property="og:image" />

        <meta content="summary" name="twitter:card" />
        <meta content="@ultroNeousTech" name="twitter:site" />
        <meta content={twitterTitle} name="twitter:title" />
        <meta content={twitterDescription} name="twitter:description" />
        <meta content={twitterImage} name="twitter:image" />

        {uniqueMeta ?? <meta content="index, follow" name="robots" />}
        <link href="/static/favicon.svg" rel="shortcut icon" />
        <link href={canonicalLink} rel="canonical" />
     
      </Head>
      <Cookie />
      <MegaMenu />
      {children}
      <Footer />
    </Fragment>
  );
}

Layout.defaultProps = {
  title:
    'Best Mobile & Web App Development Company in USA/India | ultroNeous Technologies',
  description:
    'ultroNeous Technologies is a top web app development company in USA & India, which also provides solutions for mobile app development and software development and cost-effective pricing.',
  keywords: ''
};
