import Image from "next/image";
import { memo } from "react";
import Container from "../common/Container";

const ClientLogo = () => {
  return (
    <div className="bg-primary-black">
      <Container large className="bg-black-chocolate mx-4 md:mx-0">
        <div className="py-16">
          <div className="flex flex-col md:flex-row gap-16 justify-between items-center">
            <h5 className="text-base lg:text-2xl text-nowrap text-white m-0">
              <span>Trusted by</span>
            </h5>
            <Image
              alt="Food-Panda"
              height={43}
              quality={100}
              src="/assets/common/foodpanda.webp"
              title="Food-Panda"
              width={212}
            />
            <Image
              alt="Logoplaste"
              height={30}
              quality={100}
              src="/assets/common/logoplaste.webp"
              title="Logoplaste"
              width={190}
            />
            <Image
              alt="Orocobre"
              height={45}
              quality={100}
              src="/assets/common/orocobre.webp"
              title="Orocobre"
              width={174}
            />
            <Image
              alt="Pascan Aviation"
              height={53}
              quality={100}
              src="/assets/common/pascan.webp"
              title="Pascan Aviation"
              width={131}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default memo(ClientLogo);
