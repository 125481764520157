function HomeHero() {
  return (
    <div className="bg-header-background bg-bottom-center bg-no-repeat bg-cover pt-44 md:pt-52 md:pb-0 lg:pt-72 pb-16 lg:pb-20">
      <h1 className="text-4xl leading-snug md:text-5xl md:leading-snug xl:text-7xl xl:leading-tight font-medium text-white px-4 md:px-8 lg:pl-10 mb-8 xl:w-3/5">
        <span>Software Development Company & UI/UX Design Services</span>
      </h1>
      <p className="text-white/80 text-base leading-normal lg:text-xl font-normal px-4 md:px-8 lg:px-12 pb-4 md:w-11/12 xl:w-1/2">
        <span className="inline-block">
          ultroNeous is a trusted custom software development company, that
          delivers software solutions with cutting edge technologies and it
          staff augmentation.
        </span>
      </p>
    </div>
  );
}
export default HomeHero;
