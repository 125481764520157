import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LINK_OF_ALL_PAGES } from "const/common";
import { SERVICES_LIST_DATA } from "const/Home-Page";
import dynamic from "next/dynamic";
import Link from "next/link";
import ClientLogo from "../common/ClientLogo";
import PrimaryButton from "../common/PrimaryButton";
const Video = dynamic(() => import("../common/Video"));

const ServicesCard = () => (
  <div className="bg-primary-black">
    <div className="overflow-hidden">
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-6 bg-primary-black py-8 px-4 md:px-10">
        {SERVICES_LIST_DATA.map((value) => (
          <div key={value.id} className="pb-6 xl:pb-0">
            <div className="h-full py-4 px-2 md:p-4 bg-primary-black border border-solid border-white border-opacity-20 rounded-xl transition-all duration-500 ease-out lg:hover:ease-in lg:hover:border-white lg:hover:-translate-y-5 lg:hover:border-opacity-100">
              <Link href={value.link}>
                <a>
                  <h2 className="text-white text-lg md:text-2xl py-2 font-medium md:w-11/12 lg:w-full xl:min-h-20">
                    <span>{value.heading}</span>
                  </h2>
                  <ul className="list-none text-base font-light text-white opacity-70 p-0 mb-4">
                    {value.list.map((listItems) => (
                      <li key={listItems} className="py-2">
                        <span className="flex items-center">
                          <FontAwesomeIcon
                            className="text-primary-yellow mr-2 w-5 h-5"
                            icon={faCaretRight}
                          />
                          {listItems}
                        </span>
                      </li>
                    ))}
                  </ul>
                </a>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className="pb-20 md:pb-0">
      <p className="text-white text-center mb-0 text-3xl md:text-4xl lg:text-5xl pt-8 px-8 pb-16 md:pt-8 lg:pt-16 md:pb-8 lg:px-0 -mt-8 md:mt-0 leading-snug md:leading-normal lg:leading-normal">
        <span>
          Still not clear with the next step? <br /> Don’t worry! Get a free
          consultation now.
        </span>
      </p>
      <div className="flex justify-center">
        <PrimaryButton
          link={LINK_OF_ALL_PAGES.mainPages.contactUs}
          val="Get Free Consultation"
        />
      </div>
    </div>
    <div className="py-8 md:py-16 md:px-0 lg:px-10">
      <ClientLogo />
    </div>
    <div className="flex justify-center">
      <Video
        className="rounded-2xl w-[275px] h-40 md:w-[670px] md:h-96 lg:w-[936px] lg:h-[525px]"
        height={525}
        width={936}
      />
    </div>
    <p className="text-white text-center mx-auto mb-0 text-base pt-8 px-4 pb-16 md:px-0 md:pt-24 md:w-3/4 [&>a]:text-blue-700">
      <span>
        Our journey as a full-cycle&nbsp;
        <Link href="https://ultroneous.com/">software development company</Link>
        &nbsp;has been nothing short but awesome. When you come up with your
        idea, we support and conceptualize it, transform it, secure it, and
        prioritize it based on your timeline and budget, so that you get a
        bespoke product that meets your business needs. Our team unlocks your
        idea&apos;s true potential using the experience we have developed
        working with various industry domains from multiple countries across
        user research, UI/UX design and software development.
        <br />
        We take pride in our work and we leverage our software development
        outsourcing services to define your digital identity with an approach
        rooted in design-thinking tactics and agile methodology. We cover
        everything from requirement analysis, design, and development to
        testing, deployment, and maintenance. We are your one stop solution you
        can rely on. Many people are part of our family already and we would
        love to be your software outsourcing partner, executing everything
        successfully.
      </span>
    </p>
  </div>
);

export default ServicesCard;
